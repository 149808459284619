import { call, takeLatest, takeEvery, all, debounce } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '@utils/functions';

const Api = {
  getApplicants: (journeyId) => axios.get(`/journeys/${journeyId}/applicants?limit=100`),
  getApplicant: ({ journeyId, applicantId }) => axios.get(`/journeys/${journeyId}/applicants/${applicantId}`),
  createApplicant: ({ journeyId, body }) => axios.post(`/journeys/${journeyId}/applicants`, body),
  updateApplicant: ({ journeyId, applicantId, body }) => axios.put(`/journeys/${journeyId}/applicants/${applicantId}`, body),
  deleteApplicant: ({ journeyId, applicantId }) => axios.delete(`/journeys/${journeyId}/applicants/${applicantId}`),
};

export const actionTypes = {
  GET_APPLICANTS: 'GET_APPLICANTS',
  GET_APPLICANTS_WITH_DEBOUNCE: 'GET_APPLICANTS_WITH_DEBOUNCE',
  GET_APPLICANT: 'GET_APPLICANT',
  CREATE_APPLICANT: 'CREATE_APPLICANT',
  UPDATE_APPLICANT: 'UPDATE_APPLICANT',
  DELETE_APPLICANT: 'DELETE_APPLICANT',
  DELETE_APPLICANTS: 'DELETE_APPLICANTS',
};

function* sagaGetApplicants({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getApplicants, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetApplicant({ payload: { journeyId, applicantId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getApplicant, { journeyId, applicantId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateApplicant({ payload: { journeyId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createApplicant, { journeyId, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateApplicant({ payload: { journeyId, applicantId, body, onSuccess, onError } }) {
  try {
    const { user, ...otherFields } = body;
    const result = yield call(Api.updateApplicant, { journeyId, applicantId, body: otherFields });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteApplicant({ payload: { journeyId, applicantId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteApplicant, { journeyId, applicantId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

//TODO use as example
function* sagaDeleteApplicants({ payload: { journeyId, applicantsIds, onSuccess, onError } }) {
  try {
    const requests = [];
    applicantsIds.forEach((applicantId) => requests.push(call(Api.deleteApplicant, { journeyId, applicantId })));
    const results = yield all(requests);
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess();
    } else if (isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getApplicants = payload => ({ type: actionTypes.GET_APPLICANTS, payload });
export const getApplicantsWithDebounce = payload => ({ type: actionTypes.GET_APPLICANTS_WITH_DEBOUNCE, payload });
export const getApplicant = payload => ({ type: actionTypes.GET_APPLICANT, payload });
export const createApplicant = payload => ({ type: actionTypes.CREATE_APPLICANT, payload });
export const updateApplicant = payload => ({ type: actionTypes.UPDATE_APPLICANT, payload });
export const deleteApplicant = payload => ({ type: actionTypes.DELETE_APPLICANT, payload });
export const deleteApplicants = payload => ({ type: actionTypes.DELETE_APPLICANTS, payload });

const applicantsSagas = [
  takeEvery(actionTypes.GET_APPLICANTS, sagaGetApplicants),
  debounce(150, actionTypes.GET_APPLICANTS_WITH_DEBOUNCE, sagaGetApplicants),
  takeLatest(actionTypes.GET_APPLICANT, sagaGetApplicant),
  takeEvery(actionTypes.CREATE_APPLICANT, sagaCreateApplicant),
  takeEvery(actionTypes.UPDATE_APPLICANT, sagaUpdateApplicant),
  takeEvery(actionTypes.DELETE_APPLICANT, sagaDeleteApplicant),
  takeEvery(actionTypes.DELETE_APPLICANTS, sagaDeleteApplicants)
];

export default applicantsSagas;
