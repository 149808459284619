import React, {lazy} from 'react';
import { AuthRoute } from '../auth/components';
import sagas from './sagas';
import reducers from './reducers';
import { USER_ROLE } from '../../utils/authorisation';
//import { DocumentPreview, DocumentsPage, DocumentsUploadingRoot } from './pages';


const DocumentPreview = lazy(() => import('./pages/DocumentPreview'));
const DocumentsPage = lazy(() => import('./pages/DocumentsPage'));
const DocumentsUploadingRoot = lazy(() => import('./pages/DocumentsUploadingRoot'));

const documentsModule = {
  routes: [
    <AuthRoute key="documents" exact path="/documents" component={DocumentsPage} headerType={'private'} />,
    <AuthRoute key="documents"
               exact
               path="/documents-specialist/:journeyId"
               component={DocumentsPage}
               headerType={'private'}
               componentProps={{isSpecialistPage: true}}
               isSpecialistOnly
    />,
    <AuthRoute key="documents-uploading"
               exact
               path="/documents-uploading/:journeyId/:step"
               component={DocumentsUploadingRoot}
               headerType="private"
    />,
    <AuthRoute key="documents-uploading-stage"
               exact
               path="/documents-uploading/:journeyId/:step/:documentsRequestingStage"
               component={DocumentsUploadingRoot}
               headerType="private"
    />,
    <AuthRoute key="document-preview"
               exact
               path="/document-preview/:fileKey1/:fileKey2"
               component={DocumentPreview}
               shouldHideMainLayoutWrapper
    />,
  ],
  navMenuItems: [
    {
      name: 'Documents',
      path: '/documents',
      order: 10
    },
    {
      name: 'Documents',
      path: '/documents',
      isForSelectedUser: true,
      isForSelectedNonPartnerUser: true,
      allowedRoles: [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Audit],
      order: 10
    }
  ],
  sagas,
  reducers: { documents: reducers }
};

export default documentsModule;
