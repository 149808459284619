import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import MainHeader from './MainHeader';
import {useDispatch, useSelector} from 'react-redux';
import { withDimensions } from '../../HOC';
import styles from './styles.module.scss';
import modules from '@src/modules';
import { processActionTimeTrackerTick } from '@utils/helpers';
import config from '@src/config';
import { getFeatureFlags } from '@dashboard/sagas';
import { displayErrorMessage } from '@utils/displayErrorMessage';
import { setFeatureFlags } from '@common/reducers';


let intervalId;

const MainLayout = ({ children }) => {
  const { user, selectedUser } = useSelector(state => state.user);
  const { isHiddenMainLayoutWrapper, headerType } = useSelector(state => state.common);
  const dispatch = useDispatch();

  const [navMenuItems, setNavMenuItems] = useState([]);
  const [userMenuItems, setUserMenuItems] = useState([]);

  /*const filterOutUnauthorized = (menuItems) => {
    return menuItems
      .filter((item) => !item.authorization?.length || item.authorization.find((a) => (user.roles ?? []).includes(a)));
  }*/

  useEffect(() => {
    if (user) {
      dispatch(getFeatureFlags({
        onError: (data) => displayErrorMessage(data, 'Unable to retrieve the config.'),
        onSuccess: (data) => { dispatch(setFeatureFlags(data ?? [])); }
      }));
    }

    if (!!intervalId) clearInterval(intervalId);
    intervalId = setInterval(() => {
      processActionTimeTrackerTick(user);
    }, config.TIME_LOGGER_PERIOD_SECONDS * 1000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [user]);//eslint-disable-line

  useEffect(() => {
    setNavMenuItems(modules.navMenuItems);
    setUserMenuItems((modules.userMenuItems ?? []).sort((a, b) => (a.order ?? 100) - (b.order ?? 100)));
  }, [user, selectedUser]); // eslint-disable-line



  return (
      <Box className={`${styles.globalContainer} ${isHiddenMainLayoutWrapper ? styles.noBottomPadding : ''}`}>
        {!isHiddenMainLayoutWrapper && (<>
          <MainHeader navMenuItems={navMenuItems} userMenuItems={userMenuItems} />
          {headerType === 'registrationWizard' && (<Box className={styles.substrate} />)}
        </>)}
        {children}
      </Box>
  )
};

export default withDimensions(MainLayout);