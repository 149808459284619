import React, { useEffect, useState } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { setIsHiddenMainLayoutWrapper, setHeaderType, setRowsPerPage } from '../../../common/reducers';
import { clearSelectedUser, getSelectedUser, getUser, logOut } from '../../../user/sagas';
import { Box } from '@mui/material';
import { displayErrorMessage } from '@utils/displayErrorMessage';
import history from '@utils/history';
import config from '@src/config';
import BlockedLogOutModal from '@common/components/BlockedLogOutModal';
import { setRowsPerPageLimit } from '@utils/helpers';
import { isPartner, USER_ROLE, isAllowedByRoles } from '@utils/authorisation';
import {setIsPartnerCustomerMode} from "@user/reducers";


const AuthRoute = ({
  component: Component,
  authorization = [],
  shouldHideMainLayoutWrapper,
  headerType,
  location,
  isSpecialistOnly = false,
  isPartnerOnly = false,
  componentProps = {},
  ...rest
}) => {
  const dispatch = useDispatch();
  const { selected_user } = queryString.parse(location.search);
  const { user, isLoadedUser, selectedUser } = useSelector(state => state.user);
  const { rowsPerPage } = useSelector(state => state.pagination);
  const [isLoadingSelectedUser, setIsLoadingSelectedUser] = useState(true);
  const [pathName, setPathName] = useState(true);

  useEffect(() => {

    dispatch(setIsHiddenMainLayoutWrapper(!!shouldHideMainLayoutWrapper));
    dispatch(setHeaderType(headerType ?? 'private'));
    if (!user?.mobile || !user.id) {
      dispatch(getUser());
    }
    if (!rowsPerPage) {
      setRowsPerPageLimit(rowsPerPage, setNewLimit);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]); // eslint-disable-line

  useEffect(() => {
    if (config.ENV === 'prod') {
      window._mfq = window._mfq || [];
      window._mfq.push(["setVariable", "userPhone", user?.mobile ?? '']);
    }

    if (user) {
      if (isPartner(user)) {
        if (localStorage.getItem('PARTNERS-CUSTOMER-MODE') === 'true') dispatch(setIsPartnerCustomerMode(true));
      } else {
        localStorage.removeItem('PARTNERS-CUSTOMER-MODE');
      }
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (isLoadedUser && !user?.mobile && !user?.id) dispatch(logOut({}));
    else if (isLoadedUser) {

      if (user?.roles?.length && !!user.roles.find((role) => role !== USER_ROLE.Customer)) {
        if (selected_user && selectedUser?.id !== selected_user) {
          doRetrieveSelectedUser(selected_user);
        } else if (selectedUser?.id) {
          setIsLoadingSelectedUser(false);
          if (!selected_user) {
            addQueryParam('selected_user', selectedUser.id);
          }
        } else {
          setIsLoadingSelectedUser(false);
        }
      } else {
        setIsLoadingSelectedUser(false);
        dispatch(clearSelectedUser({
          onSuccess: () => { },
          onError: () => { displayErrorMessage(null, 'Unable to clear selected user'); }
        }));
      }

    }
  }, [isLoadedUser, pathName]); // eslint-disable-line

  const setNewLimit = (value) => {
    dispatch(setRowsPerPage(value));
    localStorage.setItem('ROWS-PER-PAGE', value.toString());
  };

  const doRetrieveSelectedUser = (userId) => {
    setIsLoadingSelectedUser(true);
    dispatch(getSelectedUser({
      id: userId,
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve selected user');
      },
      onSuccess: (data) => {
        setIsLoadingSelectedUser(false);
        addQueryParam('selected_user', data.id);
      }
    }));
  };

  const addQueryParam = (key, value) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);

    if (searchParams.has(key)) searchParams.set(key, value.toString());
    else searchParams.append(key, value.toString());

    history.push({
      pathname: pathname,
      search: searchParams.toString()
    });
  };

  const checkIfAccessible = () => {
    let result = (!isSpecialistOnly || (user?.roles?.length && !!user.roles.find((role) => role !== USER_ROLE.Customer) && !isPartner(user))) && (!isPartnerOnly || isPartner(selectedUser ?? user));
    if (authorization) {
      result = result && isAllowedByRoles(user, authorization);
    }

    if (result && user?.id && !!localStorage.getItem('X-ACCESS-TOKEN')) {
      setTimeout(() => {
        localStorage.removeItem('TARGET-URL');
      },2000);
    }

    return !!result;
  };

  return (
    <>
      {isLoadedUser && !isLoadingSelectedUser ? (
        <Route
          {...rest}
          render={(props) =>
            user?.mobile && user.id ? (
              checkIfAccessible() ? (
                <Component {...props} {...componentProps} />
              ) : (
                <Redirect to="/dashboard" />
              )
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      ) : (
        <Box sx={{ mt: 12, ml: 10 }} />
      )}
      <BlockedLogOutModal />
    </>
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any,
  shouldHideMainLayoutWrapper: PropTypes.bool,
  authorization: PropTypes.arrayOf(PropTypes.string),
  headerType: PropTypes.string,
  isForSpecialist: PropTypes.bool,
  componentProps: PropTypes.object
};

export default withRouter(AuthRoute);
